<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Template Event</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious" icon="pi pi-chevron-left" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Admin Name : {{ this.localAdminName ? this.localAdminName : '-' }} </h6>
                    </span>
                </div>
                <DataTable :value="templateEventList" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage($event)" class="p-datatable-users" data-key="map1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Template Events</h5>
                        </template>
                        <template v-slot:right>
                            <Button label="Add Template Event" icon="pi pi-plus" class="p-mr-2"
                                @click="addTemplateEventRedirect(data)" />
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column field="name" header=" Event Name" headerStyle="width: 30%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.te3 ? data.te3 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Channel Name" headerStyle="width: 25%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">
                                <span v-for="(value, index) in data.te4.split(',')" :key="index">
                                    <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts" v-if="value == 1"> <span>
                                            WhatsApp</span>
                                    </Chip>
                                    <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts" v-if="value == 2"> <span>
                                            RCS</span>
                                    </Chip>
                                    <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts" v-if="value == 3"> <span>
                                            SMS</span>
                                    </Chip>
                                    <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts" v-if="value == 4"> <span>
                                            Email</span>
                                    </Chip>
                                </span>
                            </div>
                        </template>
                    </Column>
                    <Column header="Status" headerStyle="width: 25%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">
                                <span v-if="data.te5 == 1"> Active</span>
                                <span v-if="data.te5 == 2"> Inactive</span>
                            </div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 10%" class="p-text-center">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded "
                                @click="editTemplateEventDialogOpen(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import router from '@/router';
export default {
    data() {
        return {
            v$: useValidate(),
            templateEventList: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            localActionId: '',
            localAdminId: '',
            localAdminName: '',
            items_per_page: 30
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.localActionId = localStorage.localActionId;
        this.localAdminId = localStorage.localAdminId;
        this.localAdminName = localStorage.localAdminName;
        this.getAdminTemplateActionsOnly();
        this.storage_path = JSON.parse(localStorage.masterBucketName);
        this.imagestorageimgpath = this.storage_path;
        this.getTemplateEvents();
    },
    validations() {
        return {
        };
    },
    methods: {
        onSubmit() {
            return;
        },

        addTemplateEventRedirect() {
            router.push({
                name: "addEditEventTemplate",
                params: { eventId: 0 },
            });
        },

        getTemplateEvents() {
            this.loading = true;
            this.ApiService.getTemplateEvents({ actionId: this.localActionId, page_no: this.page_no, items_per_page: this.items_per_page }).then((data) => {
                if (data.status == 200) {
                    this.templateEventList = data.result;
                    this.totalRecords = data.count;
                    this.loading = false;
                } else {
                    this.templateEventList = '';
                    this.loading = false;
                }
                this.loading = false;
            });
        },

        getAdminTemplateActionsOnly(ev) {
            this.loading = true;
            this.ApiService.getAdminTemplateActionsOnly(ev).then((data) => {
                if (data.status == 200) {
                    this.templateActionList = data.data;
                    this.loading = false;
                } else {
                    this.templateActionList = '';
                    this.loading = false;
                }
                this.loading = false;
            });
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getTemplateEvents();
        },

        editTemplateEventDialogOpen(e) {
            router.push({
                name: "addEditEventTemplate",
                params: { eventId: e.te1 },
            });
        },

        goToDashboard() {
            router.push({
                name: "admindashboard",
                params: { adminId: this.localAdminId },

            });
        },

        goToPrevious() {
            router.push({
                name: "template-actions",
                params: { adminSubId: this.localAdminId },

            });
        },
    },
};
</script>
<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>
